import {
  unstable_defineLoader as defineLoader,
  unstable_data as data,
  HeadersFunction,
} from '@remix-run/node'

import {
  type MetaArgs_SingleFetch,
  Outlet,
  redirect,
  useLoaderData,
} from '@remix-run/react'

import { Header } from '~/components/Header'
import { ToggleAffiliateOffers } from '~/components/ToggleAffiliateOffers'
import { getLoungesDirectoryCached } from '~/models/lounges.server'
import { notFound } from '~/utils/responses.server'
import { getServerTiming, headersWithTiming } from '~/timing.server'
import { getSocialMetas } from '~/utils/utils'
import { httpsInProduction } from '~/utils/utils.server'

export const headers = headersWithTiming()

export function meta({ location }: MetaArgs_SingleFetch) {
  return [
    ...getSocialMetas({
      url: `https://buy.loungepair.com${location.pathname}`,
    }),
  ]
}

export const handle = {
  getSitemapEntries: () => {
    return null
  },
}

export const loader = defineLoader(async (args) => {
  const { time, getServerTimingHeader } = getServerTiming()

  if (!['GET', 'HEAD'].includes(args.request.method.toUpperCase())) {
    throw notFound(null)
  }

  const { airports } = await time(
    'getLoungesDirectoryCached',
    getLoungesDirectoryCached(args),
  )

  const counts = {
    airports: airports.length,
    lounges: airports
      .flatMap((a) => a.lounges.length)
      .reduce((a, b) => a + b, 0),
  }

  return data(
    { counts },
    {
      headers: getServerTimingHeader(),
    },
  )
})

export default function Directory() {
  const { counts } = useLoaderData<typeof loader>()

  return (
    <div className="my-6 w-full h-screen mx-auto prose home prose-stone prose-h1:font-bold">
      <Header />
      <h1 className="text-center">Best Airport Lounge Deals</h1>
      <dl className="grid grid-cols-2 mb-8 overflow-hidden text-center bg-white divide-x rounded-lg divide-stone-200 not-prose">
        <div className="px-4 py-2 sm:p-6">
          <dd className="flex justify-between mt-1 items md:block lg:flex">
            <div
              title="lounges-count"
              className="flex items-center justify-center w-full text-4xl font-bold text-stone-700"
            >
              {counts.lounges}
            </div>
          </dd>
          <dt className="text-sm font-normal truncate sm:text-lg text-stone-500">
            airport lounges
          </dt>
        </div>
        <div className="px-4 py-2 sm:p-6">
          <dd className="flex justify-between mt-1 items md:block lg:flex">
            <div
              title="airports-count"
              className="flex items-center justify-center w-full text-4xl font-bold text-stone-700"
            >
              {counts.airports}
            </div>
          </dd>
          <dt className="text-sm font-normal sm:text-lg text-stone-500">
            airports worldwide
          </dt>
        </div>
      </dl>
      <ToggleAffiliateOffers />
      <Outlet />
    </div>
  )
}
